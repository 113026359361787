@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "circe-rounded";
  src: url("https://use.typekit.net/af/83236a/00000000000000007735afd6/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff2"), url("https://use.typekit.net/af/83236a/00000000000000007735afd6/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("woff"), url("https://use.typekit.net/af/83236a/00000000000000007735afd6/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n4&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 400;
  font-stretch: normal;
}

@font-face {
  font-family: "circe-rounded";
  src: url("https://use.typekit.net/af/154410/00000000000000007735afdd/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff2"), url("https://use.typekit.net/af/154410/00000000000000007735afdd/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("woff"), url("https://use.typekit.net/af/154410/00000000000000007735afdd/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n7&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 700;
  font-stretch: normal;
}

@font-face {
  font-family: "circe-rounded";
  src: url("https://use.typekit.net/af/beac78/00000000000000007735afe3/30/l?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff2"), url("https://use.typekit.net/af/beac78/00000000000000007735afe3/30/d?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("woff"), url("https://use.typekit.net/af/beac78/00000000000000007735afe3/30/a?primer=7cdcb44be4a7db8877ffa5c0007b8dd865b3bbc383831fe2ea177f62257a9191&fvd=n8&v=3") format("opentype");
  font-display: auto;
  font-style: normal;
  font-weight: 800;
  font-stretch: normal;
}

html, body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
}

body {
  @apply text-blue font-circe-rounded;
  background: linear-gradient(to bottom, #135195, #003A77);
  background-repeat: no-repeat;
  background-attachment: fixed;
}

@media only screen and (min-height: 600px) {
  .page {
    height: 100%;
    width: 100%;
  }
}

.page-enter .page-bg {
  opacity: 0;
  transform: scale(0.98);
}

.page-enter-active .page-bg {
  opacity: 1;
  transform: scale(1);
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit .page-bg {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active .page-bg {
  opacity: 0;
  transform: scale(1.02);
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 300ms, transform 300ms;
}

.page-enter .page-content {
  opacity: 0;
  transform: translateY(20px);
}

.page-enter-active .page-content {
  opacity: 1;
  transform: translateY(0px);
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit .page-content {
  opacity: 1;
  transform: translateY(0px);
}

.page-exit-active .page-content {
  opacity: 0;
  transform: translateY(-20px);
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 300ms, transform 300ms;
}

.hyphens-auto {
  hyphens: auto;
}