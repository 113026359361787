.winner-bg {
  background-image: url('./../../../public/images/Present.png');
  background-image: -webkit-image-set(url("./../../../public/images/Present.png") 1x,
      url("./../../../public/images/Present@2x.png") 2x,
      url("./../../../public/images/Present@3x.png") 3x);
  background-image: image-set(url("./../../../public/images/Present.png") 1x,
      url("./../../../public/images/Present@2x.png") 2x,
      url("./../../../public/images/Present@3x.png") 3x);
}

.loser-bg {
  background-image: url('./../../../public/images/Sad.png');
  background-image: -webkit-image-set(url("./../../../public/images/Sad.png") 1x,
      url("./../../../public/images/Sad@2x.png") 2x,
      url("./../../../public/images/Sad@3x.png") 3x);
  background-image: image-set(url("./../../../public/images/Sad.png") 1x,
      url("./../../../public/images/Sad@2x.png") 2x,
      url("./../../../public/images/Sad@3x.png") 3x);
}

.zoom-in {
  opacity: 1;
  transform: scale(0.4);
  animation: zoomIn 0.6s cubic-bezier(0.16, 1, 0.3, 1) forwards;
}

@keyframes zoomIn {
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.jump-shake {
  animation: jumpShaking 2.8s infinite;
}

@keyframes jumpShaking {
  0% {
    transform: translateX(0);
  }
  20% {
    transform: translateX(0);
  }
  40% {
    transform: translateY(-9px);
  }

  45% {
    transform: translateY(-9px) rotate(17deg);
  }

  50% {
    transform: translateY(-9px) rotate(-17deg);
  }

  55% {
    transform: translateY(-9px) rotate(17deg);
  }

  60% {
    transform: translateY(-9px) rotate(-17deg);
  }

  80% {
    transform: translateY(0) rotate(0);
  }
}