.slide-up-enter {
  opacity: 0;
  transform: translateY(20px);
}

.slide-up-enter-active {
  opacity: 1;
  transform: translateY(0px);
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 300ms, transform 300ms;
}

.slide-up-exit {
  opacity: 1;
  transform: translateY(0px);
}

.slide-up-exit-active {
  opacity: 0;
  transform: translateY(-20px);
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 300ms, transform 300ms;
}

.slide-left-enter {
  opacity: 0;
  transform: translateX(20px);
}

.slide-left-enter-active {
  opacity: 1;
  transform: translateX(0px);
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 300ms, transform 300ms;
}

.slide-left-exit {
  opacity: 1;
  transform: translateX(0px);
}

.slide-left-exit-active {
  opacity: 0;
  transform: translateX(-20px);
  transition-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  transition: opacity 300ms, transform 300ms;
}